<template>
  <BOverlay
    :show="loading"
    spinner-variant="primary"
    variant="light"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <BCard>
      <BRow class="justify-between items-center">
        <BCol md="6">
          <strong class="text-black font-bold text-xl ml-1">Grafik Registrasi & Komisi Affiliasi</strong>
        </BCol>
        <BCol
          class="text-center flex"
          md="auto"
        >
          <BButton
            id="popoverServiceChart"
            class="btn-sm d-flex w-40 justify-between items-center mr-1"
            type="button"
            aria-expanded="false"
            size="small"
            variant="outline-secondary"
            style="border-color: #e2e2e2 !important; min-width: 100px; max-width: 100px;"
          >
            <div class="d-flex align-items-center">
              <img
                v-if="isKomship"
                :src="iconKomship"
                alt="Komship"
                :style="isBoostr ? 'width: 18px; margin-left: -8px' : 'width: 16px; margin-left: -8px'
                  && isKomship ? 'width: 16px; margin-left: -8px; margin-right: 4px' : 'width: 16px; margin-left: -8px; margin-right: 4px'"
              >
              <img
                v-if="isBoostr"
                :src="iconBooster"
                alt="Komship"
                :style="isBoostr ? 'width: 18px; margin-left: -8px' : 'width: 16px; margin-left: -8px'
                  && isKomship ? 'width: 16px; margin-left: -8px; margin-right: 4px' : 'width: 16px; margin-left: -8px; margin-right: 4px'"
              >
              <img
                v-if="isKompack"
                :src="iconKompack"
                alt="Komship"
                :style="isBoostr ? 'width: 18px; margin-left: -8px' : 'width: 16px; margin-left: -8px'
                  && isKomship ? 'width: 16px; margin-left: -8px; margin-right: 4px' : 'width: 16px; margin-left: -8px; margin-right: 4px'"
              >
              <strong
                v-if="serviceName === 'komship'"
                class="ms-2 capitalize text-black"
                :style="isBoostr ? 'margin-left: 2px; ' : 'margin-left: 2px'"
              >
                Komship
              </strong>
              <strong
                v-if="serviceName === 'boostr'"
                class="ms-2 capitalize text-black"
                :style="isBoostr ? 'margin-left: 2px; ' : 'margin-left: 2px'"
              >
                Komplace
              </strong>
              <strong
                v-if="serviceName === 'kompack'"
                class="ms-2 capitalize text-black"
                :style="isBoostr ? 'margin-left: 2px; ' : 'margin-left: 2px'"
              >
                Kompack
              </strong>
            </div>
          </BButton>
          <BPopover
            id="popover-filter-type"
            target="popoverServiceChart"
            triggers="focus"
            placement="bottom"
            style="padding: 0px !important"
          >
            <ul style="margin: -8px -10px -8px -10px;">
              <li
                v-for="(value, idx) in platform"
                :key="idx"
              >
                <BButton
                  class="d-flex align-items-center h-12"
                  style="padding-left: 0px; width: 100px; height: 31px;"
                  variant="flat-dark"
                  @click="changePlatform(value.name)"
                >
                  <img
                    :src="value.image"
                    alt="Komerce"
                    style="width:20px"
                  >
                  <strong
                    v-if="value.name === 'komship'"
                    class="ms-2 capitalize text-black"
                  >Komship</strong>
                  <strong
                    v-if="value.name === 'boostr'"
                    class="ms-2 capitalize text-black"
                  >Komplace</strong>
                  <strong
                    v-if="value.name === 'kompack'"
                    class="ms-2 capitalize text-black"
                  >Kompack</strong>
                </BButton>
              </li>
            </ul>
          </BPopover>
          <MonthlyPicker
            v-model="filter.month"
            date-format="MMM YYYY"
            place-holder="Pilih Bulan"
            :month-labels="monthlabel"
            :max="maxDatePicker"
          />
        </BCol>
      </BRow>
      <Apexcharts
        ref="myChart"
        height="300"
        type="area"
        :options="chartOptionsMember"
        :series="seriesChart"
      />
    </BCard>
  </BOverlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol,
} from 'bootstrap-vue'
import Apexcharts from 'vue-apexcharts'
import MonthlyPicker from 'vue-monthly-picker'
import { DATE, LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import { affiliateAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { chartOptionsMember } from '../config'

export default {
  components: {
    BOverlay, BCard, BRow, BCol, Apexcharts, MonthlyPicker,
  },
  data() {
    return {
      monthlabel: LABELMONTH,
      chartOptionsMember,
      seriesChart: [],
      filter: { month: this.$moment() },
      loading: false,
      idAffiliator: this.$router.history.current.params.id,
      iconKomship: 'https://storage.googleapis.com/komerce/assets/svg/icon-komship.svg',
      iconBooster: 'https://storage.googleapis.com/komerce/assets/logo/Logo-Komplace-main.svg',
      iconKompack: 'https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg',
      serviceName: 'komship',
      isKomship: true,
      isBoostr: false,
      isKompack: false,
      platform: [
        {
          image: 'https://storage.googleapis.com/komerce/assets/svg/icon-komship.svg',
          name: 'komship',
        },
        {
          image: 'https://storage.googleapis.com/komerce/assets/logo/Logo-Komplace-main.svg',
          name: 'boostr',
        },
        {
          image: 'https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg',
          name: 'kompack',
        },
      ],
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
  },
  watch: {
    'filter.month': {
      handler() {
        this.getData()
      },
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const params = {
        month: YEAR_MONTH(this.filter.month),
        service_name: this.serviceName,
      }
      const url = `/v1/member/admin/${this.idAffiliator}/count-specific-month`
      await affiliateAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.seriesChart = [
            {
              name: 'Member',
              data: data.map(item => item.count_members),
            },
            {
              name: 'Komisi (Dalam Ribuan)',
              data: data.map(item => item.count_commission / 1000),
            },
          ]
          this.chartOptionsMember = {
            ...this.chartOptionsMember,
            xaxis: {
              ...this.chartOptionsMember.xaxis,
              categories: data.map(item => DATE(item.date)),
              min: data.map(item => item.date),
            },
          }
          this.loading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
    changePlatform(params) {
      if (params === 'komship') {
        this.serviceName = 'komship'
        this.isKomship = true
        this.isBoostr = false
        this.isKompack = false
        this.getData()
      } if (params === 'boostr') {
        this.serviceName = 'boostr'
        this.isKomship = false
        this.isBoostr = true
        this.isKompack = false
        this.getData()
      } if (params === 'kompack') {
        this.serviceName = 'kompack'
        this.isKomship = false
        this.isBoostr = false
        this.isKompack = true
        this.getData()
      }
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
  },
}
</script>
